import React from "react"
import styled from "styled-components"
import { breakpoints, spacers } from "../styles/styles"

const SpacedDiv = styled.div`
    margin-bottom: ${spacers.s}px;

    @media screen AND (min-width: ${breakpoints.m}px) {
        margin-bottom: ${spacers.m}px;
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        margin-bottom: ${spacers.l}px;
    }
`

const BottomSpacing = ({children}) => <SpacedDiv>{children}</SpacedDiv>

export default BottomSpacing