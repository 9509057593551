import React from "react"
import {graphql} from "gatsby"
import Default from "../layouts/default"
import HalfFlex from "../components/HalfFlex"
import Feature from "../components/Feature"
import BottomSpacing from "../components/BottomSpacing"

export const query = graphql`
    query($path: String!) {
        allSitePage(filter: {path : {eq: $path}}) {
            edges {
                node {
                    context {
                        name
                        slug
                        full
                        features {
                            icon
                            name
                        }
                        contentImg
                        sliderImg
                        keywords
                        blurb
                    }
                }
            }
        }
    }
`



export default ({data}) => {
    const Room = data.allSitePage.edges[0].node.context;
    const PageInfo = {
    desc: Room.blurb,
    keywords: Room.keywords,
    title: Room.name + ' – Pension "Zur Altdeutschen"'
}
    return(
        <Default pageInfo={PageInfo} headerImg={Room.sliderImg} isActive="zimmer">
            <HalfFlex>
                <div>
                    {Room.features.map(feature => <Feature icon={feature.icon}>{feature.name}</Feature>)}
                </div>
                <div>
                    <BottomSpacing>
                        <img src={Room.contentImg} alt={`Bild ${Room.name}`} />
                    </BottomSpacing>
                    <h1>{Room.name}</h1>
                    <p dangerouslySetInnerHTML={{"__html": Room.full}} />
                </div>
            </HalfFlex>
        </Default>
    )
}


